function getHeaderHeight() {
    if(typeof(document.querySelector('.header-section')) != 'undefined' && document.querySelector('.header-section') != null) {
        let headerHeight = document.querySelector('.header-section').offsetHeight;
        document.documentElement.style.setProperty('--headerHeight', headerHeight + 'px');
    }
}

window.addEventListener('DOMContentLoaded', function(event) {
    getHeaderHeight();

    window.addEventListener('scroll', function(event) {
        getHeaderHeight();
    });
});
