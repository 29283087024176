export default class FormHandler {
    constructor(formElement, url, method = 'post', removeAfterSuccess = true) {
        this.formElement = formElement;
        this.url = url;
        this.method = method;
        this.removeAfterSuccess = removeAfterSuccess;
    }

    static loadRecaptcha() {
        if (window.recaptcha_site_key === '' || window.recaptcha_site_key === null) {
            return;
        }

        if (!window.hasOwnProperty('recaptcha_loaded') || window.recaptcha_loaded === false) {
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render='+window.recaptcha_site_key;
            document.body.append(script);

            window.recaptcha_loaded = true;
        }
    }

    initializeForm() {
        if (this.formElement === null) {
            return;
        }

        this.formElement.addEventListener('submit', event => {
            event.preventDefault();

            if (window.recaptcha_site_key === '' || window.recaptcha_site_key === null) {
                this.submit();
                return;
            }

            // Recaptcha
            const thisClass = this;
            grecaptcha.ready(function() {
                grecaptcha.execute(window.recaptcha_site_key, {action: 'submit'})
                    .then(function(token) {
                        thisClass.submit(token);
                    });
            });
        });
    }

    submit(token = null) {
        this.removeAllValidationErrors();

        const fields = this.getFieldsFromElement();
        const url = token === null ? this.url : this.url + '&recaptcha_token='+token;

        window.axios({
            method: this.method,
            url: url,
            data: fields,
        })
            .then(response => {
                // if response has a redirect url, redirect to that url
                if (response.data.hasOwnProperty('redirect_url')) {
                    window.location = response.data.redirect_url;
                } else {
                    const successDiv = document.createElement('div');
                    successDiv.classList.add('alert', 'alert-success');
                    successDiv.textContent = response.data.hasOwnProperty('message') ? response.data.message : 'Unknown success message.';

                    if (this.removeAfterSuccess) {
                        this.formElement.parentNode.replaceChild(successDiv, this.formElement);
                    } else {
                        this.formElement.prepend(successDiv);
                    }
                }
            })
            .catch(e => {
                if (e.hasOwnProperty('response') && e.response.status === 422) {
                    this.handleValidationErrors(e.response.data.errors);
                } else if (e.hasOwnProperty('response') && e.response.status === 412) {
                    Swal.fire('', e.response.data.message, 'error');
                } else {
                    Swal.fire('', e.message, 'error');
                }
            });
    }

    getFieldsFromElement() {
        return new FormData(this.formElement);
    }

    handleValidationErrors(errors) {
        // The fields should have the same name attribute as the error attribute
        for (const errorKey in errors) {
            const error = errors[errorKey];
            let element = this.formElement.querySelector(`[name="${errorKey}"]`);

            if (!element) {
                element = document.querySelector(`[data-label-name="${errorKey}"]`);
            }

            if (element) {
                // Add the invalid class to the div
                element.classList.add('is-invalid');

                // Create an error element for the field
                const div = document.createElement('div');
                div.classList.add('alert', 'p-0', 'mt-2', 'text-small', 'text-danger', 'fs-italic', 'validation-alert');
                div.textContent = error;

                // Add child to the parent of the form element that caused the error
                element.parentNode.appendChild(div);
            }

        }
    }

    removeAllValidationErrors() {
        // Remove all is-invalid classes
        this.formElement.querySelectorAll('input,select,textarea').forEach(el => {
            el.classList.remove('is-invalid');
        });

        // Remove all invalid alerts
        this.formElement.querySelectorAll('.validation-alert').forEach(el => {
            el.remove();
        });
    }
}
