import FormHandler from "../classes/FormHandler";

const contactForm = document.getElementById('contact-form');
const mailchimpSubscribeForm = document.getElementById('mailchimp-subscribe-form');
const pillowCodeForm = document.getElementById('pillow-code-form');
const currentLanguage = document.documentElement.lang

if (contactForm) {
    FormHandler.loadRecaptcha();
    (new FormHandler(contactForm, 'ajax/contact-form?locale='+currentLanguage, 'post')).initializeForm();
}

if (mailchimpSubscribeForm) {
    FormHandler.loadRecaptcha();
    (new FormHandler(mailchimpSubscribeForm, 'ajax/mailchimp-subscribe-form?locale='+currentLanguage, 'post')).initializeForm();
}

if (pillowCodeForm) {
    FormHandler.loadRecaptcha();
    (new FormHandler(pillowCodeForm, 'ajax/pillow-code-form?locale='+currentLanguage, 'post')).initializeForm();
}
